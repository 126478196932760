<script>
import moment from "moment";
export default {
  data() {
    return {
      fromDateDialog: false,
      toDateDialog: false,
      from: new Date().toISOString().substr(0, 10),
      to: new Date(moment(new Date()).add(1, "day"))
        .toISOString()
        .substr(0, 10),
      total_data: {},

      period: "created",
      periods: [
        { value: "created", text: "По дате создания брони" },
        { value: "booking", text: "По дате брони" }
      ]
    };
  },
  computed: {
    computedFrom() {
      return moment(this.from).format("DD.MM.YYYY");
    },
    computedTo() {
      return moment(this.to).format("DD.MM.YYYY");
    }
  },
  created() {},
  methods: {
    selectPeriod() {
      console.log(123);
    },
    navigate(path) {
      this.$router.push({
        path: "/reports/" + path,
        query: {
          start: this.from,
          end: this.to,
          check_in: this.period
        }
      });
    },
    async fetchData() {
      try {
        console.log(this.from, this.to, this.period);
        let res = await window.axios.post("/api/v2/booking/pnl/", {
          before: this.to,
          after: this.from,
          check_in: this.period == "booking"
        });
        this.total_data = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    async downloadExcel() {
      try {
        let res = (
          await window.axios({
            method: "get",
            url: "/api/v2/booking/pnl/report/",
            responseType: "blob",
            headers: {
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            },
            params: {
              before: this.to,
              after: this.from,
              check_in: this.period == "booking"
            }
          })
        ).data;
        console.log(res);
        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment"
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, "reportspnl.xlsx");
        } else {
          if (window.innerHeight < 500 || window.innerWidth < 500) {
            var ios =
              !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
            var isSafari =
              navigator.vendor &&
              navigator.vendor.indexOf("Apple") > -1 &&
              navigator.userAgent &&
              navigator.userAgent.indexOf("CriOS") == -1 &&
              navigator.userAgent.indexOf("FxiOS") == -1;
            if (ios && !isSafari) {
              toast.error("Вы можете скачать отчеты в Safari");
            } else {
              var reader = new FileReader();
              reader.fileName = "reportspnl.xlsx";
              var out = new File([res], "reportspnl.xlsx", {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment; filename='reportspnl.xlsx'"
              });
              reader.onload = function(e) {
                window.location.href = reader.result;
              };
              reader.readAsDataURL(out);
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "reportspnl.xlsx");
            if (typeof link.download === "undefined") {
              link.setAttribute("target", "_blank");
            }
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<template>
  <v-container id="reports" class="reports" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="Отчеты ПНЛ"
      class="px-3 py-3"
      style="position: relative"
    >
      <v-row class="py-3 px-5">
        <v-col cols="6" sm="3">
          <v-menu
            ref="from"
            v-model="fromDateDialog"
            :close-on-content-click="false"
            :return-value.sync="from"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFrom"
                label="С:"
                readonly
                v-bind="attrs"
                prepend-icon="mdi-calendar-blank-multiple"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="from"
              no-title
              scrollable
              locale="ru"
              :first-day-of-week="1"
            >
              <v-spacer />
              <v-btn text color="primary" @click="fromDateDialog = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="(fromDateDialog = false), $refs.from.save(from)"
              >
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="3">
          <v-menu
            ref="to"
            v-model="toDateDialog"
            :close-on-content-click="false"
            :return-value.sync="to"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedTo"
                label="По:"
                readonly
                v-bind="attrs"
                prepend-icon="mdi-calendar-blank-multiple"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="to"
              no-title
              scrollable
              locale="ru"
              :first-day-of-week="1"
            >
              <v-spacer />
              <v-btn text color="primary" @click="toDateDialog = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  toDateDialog = false;
                  $refs.to.save(to);
                "
              >
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="3">
          <v-select
            v-model="period"
            label="Тип периода"
            :items="periods"
            @change="selectPeriod"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <v-btn color="success" @click="fetchData">Фильтровать</v-btn>
          <v-btn color="primary" @click="downloadExcel">Скачать EXCEL</v-btn>
        </v-col>
      </v-row>
      <v-row class="py-3 px-5">
        <v-col cols="12" md="6">
          <h3 class="reports_title mb-4">Доходы</h3>
          <v-btn
            v-if="total_data.booking_total_price > -1"
            outlined
            color="primary"
            class="reports_button my-2"
            @click="navigate('booking')"
          >
            <div class="d-flex justify-space-between w-100">
              <p>Проживание</p>
              <p>
                {{ total_data.booking_total_price.toLocaleString("KZ-kz") }}
                тенге
              </p>
            </div>
          </v-btn>
          <v-btn
            v-if="total_data.service_total_price > -1"
            outlined
            color="primary"
            class="reports_button my-2"
            @click="navigate('service')"
          >
            <div class="d-flex justify-space-between w-100">
              <p>Услуги</p>
              <p>
                {{ total_data.service_total_price.toLocaleString("KZ-kz") }}
                тенге
              </p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="reports_title mb-4">Расходы</h3>
          <v-btn
            v-if="total_data.expenditure > -1"
            outlined
            color="primary"
            class="reports_button my-2"
            @click="navigate('expenditure')"
          >
            <div class="d-flex justify-space-between w-100">
              <p>Расход</p>
              <p>{{ total_data.expenditure.toLocaleString("KZ-kz") }} тенге</p>
            </div>
          </v-btn>
        </v-col>
      </v-row>

      <div style="display: flex; justify-content: space-between">
        <h3 class="ml-2" style="display: inline-block">
          <v-col v-if="total_data.booking_total_price > -1"
            >Доход:
            {{
              (
                total_data.booking_total_price + total_data.service_total_price
              ).toLocaleString("KZ-kz")
            }}</v-col
          >
          <v-col v-if="total_data.expenditure > -1"
            >Расходы:
            {{ total_data.expenditure.toLocaleString("KZ-kz") }}</v-col
          >
          <v-col v-if="total_data.difference > -1"
            >Разница: {{ total_data.difference.toLocaleString("KZ-kz") }}</v-col
          >
        </h3>
      </div>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>

<style scoped lang="scss">
.w-60 {
  width: 60%;
}
.reports {
  &_title {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #021011;
  }
  &_button {
    width: 60%;
    @media (orientation: portrait) {
      width: 100%;
    }
    div {
      width: 100%;
    }
    p {
      margin: 0;
    }
  }
}
</style>
